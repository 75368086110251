.form_Button {
  text-align: right;
}

.ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.input_form {
  margin-bottom: 0px;
}

.container_App {
  height: 100vh;
}

.breadcrumb_style {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #797979;
  margin-bottom: 20px;
}

.bodyCard_style {
  height: 100%;
  overflow: auto;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* overflow: auto hidden; */
}

.svg-content {
  width: 24px;
  margin-right: 10px;
}

.ant-table-thead > tr > th {
  background-color: white;
  font-family: 'Mulish' sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #797979;
}

.ant-pagination.mini .ant-pagination-item {
  border-color: #EF712B
}

.ant-pagination-item-active a {
  color: #EF712B
}

.ant-pagination-item:focus a, .ant-pagination-item:hover a {
  color: #EF712B;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #EF712B;
}

.root {
  margin: 10px 40px 30px;
}

.pathNow_style {
  font-weight: bold !important;
  color:#EF712B;
}

.header_text_detail {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #797979;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.text_detail_institusi {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: #EF712B;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.text_detail_FAQ {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: #222222;
}

.text_detail_deskripsi_acara {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
  color: #222222;
}

.text_detail_link_jalur_masuk:link {
  overflow-wrap: break-word;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
  color: #EF712B;
}

.text_detail_link_jalur_masuk:hover, .text_detail_link_jalur_masuk:focus, .text_detail_link_jalur_masuk:active{
  color: #EF712B;
}

.text_detail_note_jalur_masuk {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.headText {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  color: #EF712B;
}

.headTextLang {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #EF712B;
}

.data_text_detail {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: #222222;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.select {
  width: 100% !important;
}

.select > .ant-select-selector {
  border-radius: 5px !important;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #797979;
}

.input_style, .ant-input {
  width: -webkit-fill-available;
  border-radius: 5px;
  font-family: 'Mulish', sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #797979 !important;
}

.input_style_prefix > .ant-input {
  width: -webkit-fill-available;
  border-radius: 5px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 15px;
  color: #797979;
  margin-left: 5px;
}

.input_style_BW {
  width: -webkit-fill-available;
  padding: 7px 11px;
  border-radius: 5px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #797979;
}

.radio_text {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  color: #000000;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #EF712B
}

.ant-radio-wrapper:hover
.ant-radio, .ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #EF712B
}

.ant-radio-inner::after {
  background-color: #EF712B
}
.ant-radio-checked::after {
  border: 1px solid #EF712B
}

.resetButton > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #797979;
}


.editButton > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #EF712B;
}

.deleteButton > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #E22828;
}

.resetButton {
  margin-right: 30px;
}

.updateButton,
.updateButton:hover,
.updateButton:focus {
  margin-bottom: 0;
  background: #EF712B;
  border-radius: 1.5625rem;
  height: auto;
  color: #ececec;
  border-color: #EF712B;
}

.updateButtonOutline,
.updateButtonOutline:hover,
.updateButtonOutline:focus {
  margin-bottom: 0;
  background: #FFF;
  border-radius: 1.5625rem;
  height: auto;
  color: #EF712B;
  border-color: #EF712B;
}

.donwloadButton,
.donwloadButton:hover,
.donwloadButton:focus {
  margin-bottom: 0;
  background: #11174C;
  border-radius: 1.5625rem;
  height: auto;
  color: #ececec;
  border-color: #11174C;
}

.beasiswa-logo,
.beasiswa-logo:hover,
.beasiswa-logo:focus {
  fill: #f0f8fe;
}

.updateButton > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #ececec;
}

.deleteButton2,
.deleteButton2:hover,
.deleteButton2:focus {
  margin-bottom: 0;
  background: #b12031;
  border-radius: 1.5625rem;
  height: auto;
  color: #ececec;
  border-color: #b12031;
}

.deleteButton2 > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #ececec;
}

.nonActiveButton,
.nonActiveButton:hover,
.nonActiveButton:focus {
  margin-bottom: 0;
  background: white;
  border-radius: 1.5625rem;
  height: auto;
  color: #EF712B;
  border-color: #EF712B;
}

.nonActiveButton > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #EF712B;
}

.ant-layout-sider-children {
  height: auto;
}

.ant-breadcrumb-separator {
  font-weight: 700;
  color: #797979;
}

.background_left {
  background: linear-gradient(90deg, #0F164B 0%, #17217A 100%);
  min-height: 100vh;
  height: auto;
  padding: 25%;
  position: absolute;
  display: block;
  /* display: flex;
  align-items: center; */
}

.container_Body {
  width: 100%;
  height: 100vh;
  position: relative;
}

.text_login_content {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 50px;
  color: #ECECEC;
}

.containerVersion {
  text-align: center;
  bottom: 24px;
  position: absolute;
}

.versionText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 16px;
  color: #ECECEC;
}

.background_right {
  background-color: white;
  height: 100vh;
  padding: 50% 80px;
  /* display: flex;
  align-items: center; */
}

.text_login {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #11174C;
}

.c {
  margin-top: 12px;
  margin-bottom: 30px;
  border: 1px solid #EF712B;
}

.input_style_login {
  padding: 7px 11px;
  border-radius: 5px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #797979;
}

.input_style_modal {
  padding: 7px 11px;
  border-radius: 5px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #ECECEC;
  border: 1px solid #ECECEC;
  background-color: transparent;
}

.input_style_modal:hover,
.input_style_modal:focus {
  border-color: #ECECEC;
  box-shadow: none
}

.lupa_password_style {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #EF712B;
}


.rejectButton,
.rejectButton:hover,
.rejectButton:focus {
  margin-bottom: 0;
  margin-right: 20px;
  background: #E22828;
  border-radius: 1.5625rem;
  height: auto;
  color: #ececec;
  border-color: #E22828;
}

.rejectButton > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #ececec;
}

.approveButton,
.approveButton:hover,
.approveButton:focus {
  margin-bottom: 0;
  background: #6BC733;
  border-radius: 1.5625rem;
  height: auto;
  color: #ececec;
  border-color: #6BC733;
}

.approveButton > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #ececec;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  background-color: #BBBBBB;
  border-color: #BBBBBB;
  color: #ECECEC;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.headerForm {
  display: flex;
}

.titleModal {
  font-family: 'Poppins', sans-serif;
  margin-top: 40px;
  margin-bottom: 15px !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #222 !important;
}

.titleModalGalery {
  font-family: 'Poppins', sans-serif;
  margin-top: 15px;
  margin-bottom: 15px !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #222 !important;
}

.dividerRegis {
  border-bottom: 3px solid #ef712b;
  width: 181.03px;
  margin-bottom: 37px;
  margin-top: 18px;
}

.dividerGalery {
  border-bottom: 2px solid #ef712b;
  width: 181.03px;
  margin-bottom: 20px;
}

.dividerPlus {
  border-bottom: 2px solid #ef712b;
  margin-top: 15px;
}

.logoInstitusi {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.buttonUpload,
.buttonUpload:hover,
.buttonUpload:focus {
  margin-bottom: 0;
  background: #11174c;
  border-radius: 1.5625rem;
  height: auto;
  color: #ececec;
  border-color: #11174c;
}

.buttonUpload > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #ececec;
}


span.ant-radio + * {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20x;
  color: #000000;
}



.ant-modal-content {
  border-radius: 15px;
}

.ant-modal-body {
  border-radius: 15px;
}

.ant-card {
  border-radius: 15px;
}

.modalHead {
  background-color: #11174C;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-left: 4%;
}

.modalHeadEdit {
  background-color: #11174C;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0 40px;
}

.modalTitle {
  display: block;
  color: #ffffff;
  padding-top: 30px;
  font-weight: 600;
  border-bottom: 2px solid #EF712B;
}

.modalTitleEdit {
  display: block;
  padding-top: 30px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  color: #ECECEC;
}



.buttonMode {
  margin-top: 20px;
  padding: 1% 1.5%;
  font-size: 16px;
  font-weight: 500;
  color: #797979;
  background-color: transparent;
  border: none;
  border-radius: 5px;
}

.buttonModeFocus {
  margin-top: 20px;
  padding: 1% 1.5%;
  font-size: 16px;
  font-weight: 500;
  color: #EF712B;
  background-color: #ffffff;
  border: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  outline: 0;
  border: none;
}

.activeButton {
  background-color: #ffffff;
  outline: 0;
  color: #EF712B;
  margin-top: 20px;
  padding: 1% 1.5%;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 5px 5px 0 0;
}

.btnBack{
  padding-top: 5%;
  padding-left: 3%;
  font-size: 16px;
  color: orange;
  font-weight: 700;
  width: 5%;
  height: 7%;
  background-color: white;
}

.text_forget_password {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #11174C;
}

.btnback{
  padding-top: 5%;
}

.arrow{
  font-size: 20px;
  font-weight: 600;
}

.background_right-forget{
  background-color: white;
  height: 92.5vh;
  padding: 40% 80px;
}
.text_detail_tema {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: #11174c;
}
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
  color: rgb(0 0 0 / 30%);
  font-weight: bold;
}

.ant-input[disabled] {
  color: rgb(0 0 0 / 30%);
  font-weight: bold;
}

.modal_regis > .ant-modal-content > .ant-modal-header {
  border-radius: 15px 15px 0 0;
  background: linear-gradient(90deg, #4B0187 0%, #443DEF 100%);
}

.modal_regis > .ant-modal-content > .ant-modal-header > .ant-modal-title {
  display: none;
}

.modal_regis > .ant-modal-content > .ant-modal-close, .ant-modal-close:focus, .ant-modal-close:hover {
  color: #ECECEC;
}

.modal_regis > .ant-modal-content > .ant-modal-close > .ant-modal-close-x {
  line-height: 32px;
}

.modal_regisMobile > .ant-modal-content > .ant-modal-header {
  border-radius: 15px 15px 0 0;
  background: linear-gradient(90deg, #4B0187 0%, #443DEF 100%);
}

.modal_regisMobile > .ant-modal-content > .ant-modal-header > .ant-modal-title {
  display: none;
}

.modal_regisMobile > .ant-modal-content > .ant-modal-close, .ant-modal-close:focus, .ant-modal-close:hover {
  color: #ECECEC;
}

.modal_regisMobile > .ant-modal-content > .ant-modal-close > .ant-modal-close-x {
  line-height: 32px;
}


.headerFormText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #222222;
}

.ant-picker-range {
  width: 100%;
  border-radius: 5px;
}

.ant-picker {
  border-radius: 5px;
}

.ant-radio-wrapper {
  white-space: pre-wrap;
}

.maxUpload {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #797979;
}

.textSchedule {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.termText {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 15px;
  color: #000000;
}

.gradientCard {
  width: 100%;
  position: relative;
  display: inline-block;
}

.playButton {
  position: absolute;
  font-size: 30px;
  color: white;
  width: 100%;
  top: 35%;
  z-index: 2;
}

.gradientCard::after {
  content: '';
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background:  rgba(0, 0, 0, 0.35);
}

.cardVideo > .ant-modal-content {
  border-radius: 10px;
}

.cardVideo > .ant-modal-content > .ant-modal-body {
  padding: 40px;
}

.cardVideoMobile > .ant-modal-content {
  border-radius: 10px;
}

.cardVideoMobile > .ant-modal-content > .ant-modal-body {
  padding: 40px;
}

.ulMediaSosial > ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.ulMediaSosial > ul > li {
  margin-top: 10px;
}

.listLink {
  font-weight: 600;
  font-size: 16px;
}

.listLink:link {
  color: #797979;
}

.listLink:hover {
  color: #0e0c0b;
}

.listLink:active {
  color: #797979;
}

.listLink:visited {
  color: #797979;
}

.linkUrl:link {
  color: #ef712b;
}

.linkUrl:hover {
  color: #ef712b;
}

.linkUrl:active {
  color: #ef712b;
}

.linkUrl:visited {
  color: #ef712b;
}

.head_modal_center {
  text-align: center;
  padding-top: 15px;
}

.dividerModal {
  border-bottom: 2px solid #ef712b;
  width: 181.03px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.list_entrance_card {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  border-radius: 0px;
}

.title_list_entrance {
  font-family: Poppins, sans-serif;
  color: #0F164B;
  font-size: 22px;
  font-weight: 700;
}

.title_head_create_admin {
  font-family: Poppins, sans-serif;
  color: #0F164B;
  font-size: 22px;
  font-weight: 700;
  margin-top: 30px;
}

.buttonUploadForm,
.buttonUploadForm:hover,
.buttonUploadForm:focus {
  margin-bottom: 0;
  background: #11174c;
  border-radius: 1.5625rem;
  height: auto;
  padding: 1px 32px;
  color: #ececec;
  border-color: #11174c;
}

.buttonUploadForm > span {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 18px;
  color: #ececec;
}

.thumbnailActive {
  border-radius: 6px;
  border: 4px solid #EF712B;
}

.mandatory {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ff2020;
}

.mandatoryLarge {
  color: #ff2020;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.dashboardText {
  font-weight: bold;
  color: #072a6f;
}

.selectCustom > .ant-select-selector{
  border: none !important
}

.modalImage, .modal_regis, .cardVideo{
  width: 900px !important;
}

@media only screen and (max-device-width: 1224px){
  .root {
    margin: 10px 20px 30px;
  }

  .text_login_content, .headText, .title_head_create_admin, .title_list_entrance {
    font-size: 18px;
    line-height: 1.5;
  }

  .mandatoryLarge, .headTextLang {
    font-size: 16px;
    line-height: 1.5;
  }

  .background_left {
    padding: 20%;
  }
  .background_right, .background_right-forget {
    padding: 50px 20px;
    height: 100vh;
  }

  .container_Body { 
    overflow: hidden;
  }

  .text_login, .text_forget_password, .modalTitleEdit, .titleModal
  ,.titleModalGalery, .modalTitle {
    font-size: 21px !important;
    line-height: 1.5 !important;
  }

  .data_text_detail, .header, .breadcrumb_style, .logoInstitusi, .text_detail_institusi
  , .listLink, .text_detail_deskripsi_acara, .text_detail_FAQ, .headerFormText
  ,span.ant-radio + *, .buttonModeFocus  {
    font-size: 14px !important;
    line-height: 1.5  !important;
  }

  .activeButton, .buttonMode {
    font-size: 13px !important;
    line-height: 1.5  !important;

  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .header {
    padding-right: 20px !important;
    padding-top: 10px !important;
    color: rgba(255, 255, 255, 0.65) !important;
  }

  /* .playButton {
    top: 21%
  } */

  .listLink:link {
    white-space: pre;
  }

  .modalMobile, .modal_regisMobile, .cardVideoMobile{
    width: 550px !important;
  }
}

@media only screen and (orientation: landscape) {
  .modalMobile, .modal_regisMobile, .cardVideoMobile{
    width: 550px !important;
  }
}