.header {
  /* background-color: white; */
  text-align: right;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #797979;
  padding-right: 80px;
  padding-top: 20px;
}

.bold_text {
  font-weight: bold;
  color: #EF712B;
}