.header_container {
  color: #EFEEEE;
  float: left;
  font-size: 24px;
  line-height: 2.5;
  margin-right: 40px;
}

.logo {
  height: 60px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 24px 16px 16px;
  display: flex;
  align-items: center;
}

.logoMobile {
  height: 50px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 0px 16px;
  display: flex;
  align-items: center;
}

.site-layout .site-layout-background {
  background: #fff;
}

.textLogo {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.5;
  color: #ECECEC;
}

.logoHeader {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.navbarAdmin {
  overflow-y: scroll;
}

.navbarAdmin::-webkit-scrollbar {
  display: none;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #001529;
}